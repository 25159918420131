/**
 * URLS
 */
export const BASE_URL = process.env.NEXT_PUBLIC_BANTRAB_API_BASE_URL
export const SITE_ID = parseInt(process.env.NEXT_PUBLIC_BANTRAB_API_SITE_ID)

// Auth
export const SIGNUP_ENDPOINT = '/auth/signup/'
export const SIGNIN_ENDPOINT = '/auth/token/'
export const PASSWORD_RESET_ENDPOINT = '/auth/password/reset/'
export const PASSWORD_RECOVERY_ENDPOINT = '/auth/password/recovery/'
export const TOKEN_VERIFY_ENDPOINT = '/auth/token/verify/'
export const TOKEN_REFRESH_ENDPOINT = '/auth/token/refresh/'
export const COMPANIES_ENDPOINT = '/companies/'
export const SUBCOMPANIES_ENDPOINT = '/subcompanies/'
export const DEPENDENCIES_ENDPOINT = '/dependencies/'
// User
export const USER_ENDPOINT = '/me/'

// User actions
export const FAVORITES_ENDPOINT = '/favorites/'
export const ENROLLMENTS_ENDPOINT = '/enrollment/'
export const SPECIALIZATION_ENROLLMENTS_ENDPOINT = '/specialization-enrollment/'
export const RESOURCE_DOWNLOADS_ENDPOINT = '/downloads/'
export const TRANSMISSION_VIEWS_ENDPOINT = '/views/'
export const COMMENTS_ENDPOINT = '/comments/'

// CMS
export const SITES_ENDPOINT = '/site/'
export const CATEGORIES_ENDPOINT = '/cms/categories/'
export const PAGES_ENDPOINT = '/cms/pages/'
export const COURSES_ENDPOINT = PAGES_ENDPOINT
export const RESOURCES_ENDPOINT = PAGES_ENDPOINT
export const NEWS_ENDPOINT = PAGES_ENDPOINT
export const TRANSMISSIONS_ENDPOINT = PAGES_ENDPOINT
export const TESTIMONIALS_ENDPOINT = '/cms/testimonials/'
export const PAGE_COMMENTS_ENDPOINT = '/cms/comments/'
export const FORMS_SUBMISSIONS_ENDPOINT = '/form-submission/'
