import {
  BASE_URL,
  SITE_ID,
  SIGNUP_ENDPOINT,
  SIGNIN_ENDPOINT,
  PASSWORD_RESET_ENDPOINT,
  PASSWORD_RECOVERY_ENDPOINT,
  TOKEN_VERIFY_ENDPOINT,
  TOKEN_REFRESH_ENDPOINT,
  COMPANIES_ENDPOINT,
  SUBCOMPANIES_ENDPOINT,
  DEPENDENCIES_ENDPOINT,
  USER_ENDPOINT,
  FAVORITES_ENDPOINT,
  ENROLLMENTS_ENDPOINT,
  SPECIALIZATION_ENROLLMENTS_ENDPOINT,
  RESOURCE_DOWNLOADS_ENDPOINT,
  TRANSMISSION_VIEWS_ENDPOINT,
  COMMENTS_ENDPOINT,
  SITES_ENDPOINT,
  CATEGORIES_ENDPOINT,
  PAGES_ENDPOINT,
  COURSES_ENDPOINT,
  RESOURCES_ENDPOINT,
  NEWS_ENDPOINT,
  TRANSMISSIONS_ENDPOINT,
  TESTIMONIALS_ENDPOINT,
  PAGE_COMMENTS_ENDPOINT,
  FORMS_SUBMISSIONS_ENDPOINT
} from './constants'
import {
  // Auth
  signup as genericSignup,
  signin as genericSignin,
  tokenVerify as genericTokenVerify,
  tokenRefresh as genericTokenRefresh,
  passwordRecovery as genericPasswordRecovery,
  passwordReset as genericPasswordReset,
  listCompanies as genericListCompanies,
  listSubCompanies as genericListSubCompanies,
  listDependencies as genericListDependencies,
  // User
  getUser as genericGetUser,
  updateUser as genericUpdateUser,
  // User actions
  listFavorites as genericListFavorites,
  createFavorite as genericCreateFavorite,
  deleteFavorite as genericDeleteFavorite,
  createEnrollment as genericCreateEnrollment,
  createResourceDownload as genericCreateResourceDownload,
  createTransmissionView as genericCreateTransmissionView,
  createComment as genericCreateComment,
  // CMS
  getSiteInfo as genericGetSiteInfo,
  getCategories as genericGetCategories,
  getPages as genericGetPages,
  getCourses as genericGetCourses,
  getNews as genericGetNews,
  getResources as genericGetResources,
  getTransmissions as genericGetTransmissions,
  getSpecializations as genericGetSpecializations,
  getComments as genericGetComments,
  getTestimonials as genericGetTestimonials,
  submitForm as genericSubmitForm
} from '../common'

/**
 * Auth
 */
export const signup = async (data = {}) => {
  return await genericSignup(BASE_URL, SIGNUP_ENDPOINT, data)
}

export const signin = async (data = {}) => {
  return await genericSignin(BASE_URL, SIGNIN_ENDPOINT, data)
}

export const tokenVerify = async (data = {}) => {
  return await genericTokenVerify(BASE_URL, TOKEN_VERIFY_ENDPOINT, data)
}

export const tokenRefresh = async (data = {}) => {
  return await genericTokenRefresh(BASE_URL, TOKEN_REFRESH_ENDPOINT, data)
}

export const passwordRecovery = async (data = {}) => {
  return await genericPasswordRecovery(BASE_URL, PASSWORD_RECOVERY_ENDPOINT, data)
}
export const passwordReset = async (data = {}) => {
  return await genericPasswordReset(BASE_URL, PASSWORD_RESET_ENDPOINT, data)
}

export const listCompanies = async (query = {}, headers = {}) => {
  return await genericListCompanies(BASE_URL, COMPANIES_ENDPOINT, query, headers)
}
export const listSubCompanies = async (query = {}, headers = {}) => {
  return await genericListSubCompanies(BASE_URL, SUBCOMPANIES_ENDPOINT, query, headers)
}
export const listDependencies = async (query = {}, headers = {}) => {
  return await genericListDependencies(BASE_URL, DEPENDENCIES_ENDPOINT, query, headers)
}

/**
 * User
 */
export const getUser = async (data = {}, headers = {}) => {
  return await genericGetUser(BASE_URL, USER_ENDPOINT, data, headers)
}

export const updateUser = async (data = {}, headers = {}) => {
  return await genericUpdateUser(BASE_URL, USER_ENDPOINT, data, headers)
}

/**
 * User Actions
 */
export const createEnrollment = async (data = {}, headers = {}) => {
  return await genericCreateEnrollment(BASE_URL, ENROLLMENTS_ENDPOINT, data, headers)
}

export const createSpecializationEnrollment = async (data = {}, headers = {}) => {
  return await genericCreateEnrollment(BASE_URL, SPECIALIZATION_ENROLLMENTS_ENDPOINT, data, headers)
}

export const listFavorites = async (query = {}, headers = {}) => {
  return await genericListFavorites(BASE_URL, FAVORITES_ENDPOINT, query, headers)
}

export const createFavorite = async (data = {}, headers = {}) => {
  return await genericCreateFavorite(BASE_URL, FAVORITES_ENDPOINT, data, headers)
}

export const deleteFavorite = async (favoriteId, headers = {}) => {
  return await genericDeleteFavorite(BASE_URL, FAVORITES_ENDPOINT, favoriteId, headers)
}

export const createResourceDownload = async (data, headers = {}) => {
  return await genericCreateResourceDownload(BASE_URL, RESOURCE_DOWNLOADS_ENDPOINT, data, headers)
}

export const createTransmissionView = async (data, headers = {}) => {
  return await genericCreateTransmissionView(BASE_URL, TRANSMISSION_VIEWS_ENDPOINT, data, headers)
}

export const createComment = async (data, headers = {}) => {
  return await genericCreateComment(BASE_URL, COMMENTS_ENDPOINT, data, headers)
}

/**
 * CMS
 */
export const getSiteInfo = async (query = {}, headers = {}) => {
  return await genericGetSiteInfo(BASE_URL, SITES_ENDPOINT, SITE_ID, query, headers)
}

export const search = async (query = {}, headers = {}) => {
  return await genericGetPages(BASE_URL, PAGES_ENDPOINT, query, headers)
}

export const getPage = async (id, query = {}, headers = {}) => {
  return await genericGetPages(BASE_URL, `${PAGES_ENDPOINT}${id}/`, query, headers)
}

export const getChildrenPages = async (id, query = {}, headers = {}) => {
  const defaultQuery = {
    child_of: id,
    show_in_menus: true
  }
  return await genericGetPages(BASE_URL, PAGES_ENDPOINT, { ...defaultQuery, ...query }, headers)
}

export const getCourses = async (query = {}, headers = {}) => {
  return await genericGetCourses(BASE_URL, COURSES_ENDPOINT, query, headers)
}

export const getCourse = async (id, query = {}, headers = {}) => {
  return await genericGetPages(BASE_URL, `${COURSES_ENDPOINT}${id}/`, query, headers)
}

export const getNews = async (query = {}, headers = {}) => {
  return await genericGetNews(BASE_URL, NEWS_ENDPOINT, query, headers)
}

export const getArticle = async (id, query = {}, headers = {}) => {
  return await genericGetPages(BASE_URL, `${NEWS_ENDPOINT}${id}/`, query, headers)
}

export const getResources = async (query = {}, headers = {}) => {
  return await genericGetResources(BASE_URL, RESOURCES_ENDPOINT, query, headers)
}

export const getResource = async (id, query = {}, headers = {}) => {
  return await genericGetPages(BASE_URL, `${RESOURCES_ENDPOINT}${id}/`, query, headers)
}

export const getTransmissions = async (query = {}, headers = {}) => {
  return await genericGetTransmissions(BASE_URL, TRANSMISSIONS_ENDPOINT, query, headers)
}

export const getTransmission = async (id, query = {}, headers = {}) => {
  return await genericGetPages(BASE_URL, `${TRANSMISSIONS_ENDPOINT}${id}/`, query, headers)
}

export const getSpecializations = async (query = {}, headers = {}) => {
  return await genericGetSpecializations(BASE_URL, TRANSMISSIONS_ENDPOINT, query, headers)
}

export const getSpecialization = async (id, query = {}, headers = {}) => {
  return await genericGetPages(BASE_URL, `${TRANSMISSIONS_ENDPOINT}${id}/`, query, headers)
}

export const getCategories = async (query = {}, headers = {}) => {
  return await genericGetCategories(BASE_URL, CATEGORIES_ENDPOINT, query, headers)
}

export const getTestimonials = async (query = {}, headers = {}) => {
  return await genericGetTestimonials(BASE_URL, TESTIMONIALS_ENDPOINT, query, headers)
}

export const getComments = async (query = {}, headers = {}) => {
  return await genericGetComments(BASE_URL, PAGE_COMMENTS_ENDPOINT, query, headers)
}

export const submitForm = async (data = {}) => {
  return await genericSubmitForm(BASE_URL, FORMS_SUBMISSIONS_ENDPOINT, data)
}
