import { formatURI } from './utils'

export const httpGet = async (baseUrl, path, query = {}, headers = {}) => {
  console.log('[GET]', formatURI(baseUrl, path, query))

  return await fetch(
    formatURI(baseUrl, path, query),
    {
      method: 'GET',
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      }
    }
  )
}

export const httpPost = async (baseUrl, path, data = {}, headers = {}) => {
  return await fetch(
    formatURI(baseUrl, path),
    {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  )
}

export const httpPatch = async (baseUrl, path, data = {}, headers = {}) => {
  return await fetch(
    formatURI(baseUrl, path),
    {
      method: 'PATCH',
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  )
}

export const httpDelete = async (baseUrl, path, headers = {}) => {
  return await fetch(
    formatURI(baseUrl, path),
    {
      method: 'DELETE',
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      }
    }
  )
}

/**
 * Auth Functions
 */
export const signup = async (baseUrl, path, data = {}) => {
  return await httpPost(baseUrl, path, data)
}

export const signin = async (baseUrl, path, data = {}) => {
  return await httpPost(baseUrl, path, data)
}

export const tokenVerify = async (baseUrl, path, data = {}) => {
  return await httpPost(baseUrl, path, data)
}

export const tokenRefresh = async (baseUrl, path, data = {}) => {
  return await httpPost(baseUrl, path, data)
}

export const passwordRecovery = async (baseUrl, path, data = {}) => {
  return await httpPost(baseUrl, path, data)
}

export const passwordReset = async (baseUrl, path, data = {}) => {
  return await httpPost(baseUrl, path, data)
}

export const listCompanies = async (baseUrl, path, query = {}, headers = {}) => {
  return await httpGet(baseUrl, path, query, headers)
}

export const listSubCompanies = async (baseUrl, path, query = {}, headers = {}) => {
  return await httpGet(baseUrl, path, query, headers)
}

export const listDependencies = async (baseUrl, path, query = {}, headers = {}) => {
  return await httpGet(baseUrl, path, query, headers)
}

/**
 * User functions
 */
export const getUser = async (baseUrl, path, data = {}, headers = {}) => {
  return await httpGet(baseUrl, path, data, headers)
}

export const updateUser = async (baseUrl, path, data = {}, headers = {}) => {
  return await httpPatch(baseUrl, path, data, headers)
}

/**
 * User actions
 */
export const listFavorites = async (baseUrl, path, query = {}, headers = {}) => {
  return await httpGet(baseUrl, path, query, headers)
}

export const createFavorite = async (baseUrl, path, data = {}, headers = {}) => {
  return await httpPost(baseUrl, path, data, headers)
}

export const deleteFavorite = async (baseUrl, path, favoriteId, headers = {}) => {
  return await httpDelete(baseUrl, `${path}${favoriteId}/`, headers)
}

export const createEnrollment = async (baseUrl, path, data = {}, headers = {}) => {
  return await httpPost(baseUrl, path, data, headers)
}

export const createSpecializationEnrollment = async (baseUrl, path, data = {}, headers = {}) => {
  return await httpPost(baseUrl, path, data, headers)
}

export const createResourceDownload = async (baseUrl, path, data = {}, headers = {}) => {
  return await httpPost(baseUrl, path, data, headers)
}

export const createTransmissionView = async (baseUrl, path, data = {}, headers = {}) => {
  return await httpPost(baseUrl, path, data, headers)
}

export const createComment = async (baseUrl, path, data = {}, headers = {}) => {
  return await httpPost(baseUrl, path, data, headers)
}

/**
 * Content related functions
 */
export const getSiteInfo = async (baseUrl, path, siteId, query = {}, headers = {}) => {
  return await httpGet(baseUrl, `${path}${siteId}/`, query, headers)
}

export const getCategories = async (baseUrl, path, query = {}, headers = {}) => {
  return await httpGet(baseUrl, path, query, headers)
}

export const getPages = async (baseUrl, path, query = {}, headers = {}) => {
  return await httpGet(baseUrl, path, query, headers)
}

export const getCourses = async (baseUrl, path, query = {}, headers = {}) => {
  query.type = 'courses.CoursesEntryPage'
  return await getPages(baseUrl, path, query, headers)
}

export const getNews = async (baseUrl, path, query = {}, headers = {}) => {
  query.type = 'news.NewsEntryPage'
  return await getPages(baseUrl, path, query, headers)
}

export const getResources = async (baseUrl, path, query = {}, headers = {}) => {
  query.type = 'resources.ResourcesEntryPage'
  return await getPages(baseUrl, path, query, headers)
}

export const getTransmissions = async (baseUrl, path, query = {}, headers = {}) => {
  query.type = 'transmissions.TransmissionsEntryPage'
  return await getPages(baseUrl, path, query, headers)
}

export const getSpecializations = async (baseUrl, path, query = {}, headers = {}) => {
  query.type = 'specializations.SpecializationsEntryPage'
  return await getPages(baseUrl, path, query, headers)
}
export const getComments = async (baseUrl, path, query = {}, headers = {}) => {
  return await httpGet(baseUrl, path, query, headers)
}

export const getTestimonials = async (baseUrl, path, query = {}, headers = {}) => {
  return await httpGet(baseUrl, path, query, headers)
}

export const submitForm = async (baseUrl, path, data = {}, headers = {}) => {
  return await httpPost(baseUrl, path, data, headers)
}
