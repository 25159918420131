import React, { useState, useEffect, useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

import {
  tokenVerify as bantrabTokenVerify,
  tokenRefresh as bantrabTokenRefresh,
  getUser as bantrabGetUser,
  listFavorites as bantrabListFavorites,
  getCourses as bantrabGetCourses,
  getSpecializations as bantrabGetSpecializations
} from '../api/bantrab'

const authContext = createContext()

export const AuthProvider = ({ children }) => {
  const auth = useAuthProvider()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.any
}

export const useAuth = () => {
  return useContext(authContext)
}

function useAuthProvider () {
  const router = useRouter()

  const [user, setUser] = useState(null)
  const [favorites, setFavorites] = useState(null)
  const [enrolledCourses, setEnrolledCourses] = useState([])
  const [enrolledSpecializations, setEnrolledSpecializations] = useState([])
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [accessToken, setAccessToken] = useState(null)
  const [refreshToken, setRefreshToken] = useState(null)

  const [tokenVerifyHandler, setTokenVerifyHandler] = useState(null)
  const [tokenRefreshHandler, setTokenRefreshHandler] = useState(null)
  const [userFetchHandler, setUserFetchHandler] = useState(null)
  const [favoritesFetchHandler, setFavoritesFetchHandler] = useState(null)
  const [enrroledCoursesFetchHandler, setEnrroledCoursesFetchHandler] = useState(null)
  const [enrroledSpecializationsFetchHandler, setEnrroledSpecializationsFetchHandler] = useState(null)

  const [accessTokenStorageKey, setAccessTokenStorageKey] = useState('')
  const [refreshTokenStorageKey, setRefreshTokenStorageKey] = useState('')

  const [passwordRecoveryEmailSent, setPasswordRecoveryEmailSent] = useState(false)
  const [suggestionSent, setSuggestionSent] = useState(false)

  const [commentCreated, setCommentCreated] = useState(false)

  const loadAuthFunctions = () => {
    setTokenVerifyHandler(() => { return bantrabTokenVerify })
    setTokenRefreshHandler(() => { return bantrabTokenRefresh })
    setUserFetchHandler(() => { return bantrabGetUser })
    setFavoritesFetchHandler(() => { return bantrabListFavorites })
    setEnrroledCoursesFetchHandler(() => { return bantrabGetCourses })
    setEnrroledSpecializationsFetchHandler(() => { return bantrabGetSpecializations })
    setAccessTokenStorageKey('bantrabAccessToken')
    setRefreshTokenStorageKey('bantrabRefreshToken')
  }

  const interceptor = (intercepted) => {
    return async (...args) => {
      const interceptedResponse = await intercepted.apply(this, args)
      
      if (!interceptedResponse.ok && interceptedResponse.status === 401) {
        const response = await tokenRefreshHandler({ refresh: refreshToken })
  
        if (!response.ok) {
          setupTokens({ access: null, refresh: null })
          return interceptedResponse
        }
  
        const newAccessToken = await response.json()
        setupTokens({ ...newAccessToken, refresh: refreshToken })
  
        const newInterceptedResponse = await intercepted.apply(this, args)
        return newInterceptedResponse
      }
  
      return interceptedResponse
    }
  }

  const handleRedirect = (nextUrl) => {
    if (nextUrl && router.asPath.replace(/\/$/, '') !== nextUrl.replace(/\/$/, '')) {
      router.push(nextUrl)
    }
  }

  const setupStateTokens = ({ access, refresh }) => {
    setAccessToken(access)
    setRefreshToken(refresh)
  }

  const setupStorageTokens = ({ access, refresh }) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(accessTokenStorageKey, access)
      window.localStorage.setItem(refreshTokenStorageKey, refresh)
    }
  }

  const setupTokens = ({ access, refresh }) => {
    setupStateTokens({ access, refresh })
    setupStorageTokens({ access, refresh })
  }

  const loadTokensFromStorage = () => {
    if (typeof window !== 'undefined') {
      const access = window.localStorage.getItem(accessTokenStorageKey)
      const refresh = window.localStorage.getItem(refreshTokenStorageKey)
      setupTokens({ access, refresh })
    }
  }

  const verifyTokens = async () => {
    if (accessToken !== null && refreshToken !== null) {
      const [accessTokenValidation, refreshTokenValidation] = await Promise.all([
        tokenVerifyHandler({ token: accessToken }),
        tokenVerifyHandler({ token: refreshToken })
      ])

      if (!accessTokenValidation.ok && !refreshTokenValidation.ok) {
        setupTokens({ access: null, refresh: null })
        setFavorites(null)
        setUser(null)
        return
      } else if (!accessTokenValidation.ok) {
        const response = await tokenRefreshHandler({ refresh: refreshToken })

        if (!response.ok) {
          setupTokens({ access: null, refresh: null })
          setFavorites(null)
          setUser(null)
          return
        }

        const newAccessToken = await response.json()
        setupTokens({ ...newAccessToken, refresh: refreshToken })
      }

      let user = await userFetchHandler({}, {
        Authorization: `Bearer ${accessToken}`
      })

      if (user.ok) {
        user = await user.json()
        setUser(user)
        setIsAuthenticated(true)

        let favorites = await favoritesFetchHandler({}, {
          Authorization: `Bearer ${accessToken}`
        })

        if (favorites.ok) {
          favorites = await favorites.json()
          setFavorites(favorites)
        }

        let enrolledCourses = await enrroledCoursesFetchHandler({
          fields: '*',
          subscriber: user.id
        }, {
          Authorization: `Bearer ${accessToken}`
        })

        if (enrolledCourses.ok) {
          enrolledCourses = await enrolledCourses.json()
          setEnrolledCourses(enrolledCourses.items)
        }

        let enrolledSpecializations = await enrroledSpecializationsFetchHandler({
          fields: '*',
          subscriber: user.id
        }, {
          Authorization: `Bearer ${accessToken}`
        })

        if (enrolledSpecializations.ok) {
          enrolledSpecializations = await enrolledSpecializations.json()
          setEnrolledSpecializations(enrolledSpecializations.items)
        }

        return
      }
    }

    setIsAuthenticated(false)
  }

  const signin = async ({ email, password }, handler, successURL) => {
    const response = await handler({ email, password })
    const data = await response.json()

    if (response.ok) {
      setupTokens(data)

      if (typeof window !== 'undefined') {
        if (typeof window.dataLayer !== 'undefined') {
          window.dataLayer.push({ event: 'login' })
        }
      }

      successURL && handleRedirect(successURL)
    }

    return data
  }

  const signup = async ({
    email,
    country,
    firstName,
    lastName,
    cellphone,
    password,
    idCard,
    birthday,
    company,
    subcompany,
    dependence,
    companyName,
    phone,
    termsAndConditions,
    departments
  }, handler, successURL) => {
    const response = await handler({
      email,
      country,
      firstName,
      lastName,
      cellphone,
      password,
      idCard,
      birthday,
      company,
      subcompany,
      dependence,
      companyName,
      phone,
      termsAndConditions,
    })
    const data = await response.json()

    if (response.ok) {
      setupTokens(data)

      if (typeof window !== 'undefined') {
        if (typeof window.dataLayer !== 'undefined') {
          window.dataLayer.push({ event: 'Registro_exitoso' })
        }
      }

      successURL && handleRedirect(successURL)
    }

    return data
  }

  const signout = ({ successURL = '/' }) => {
    setupTokens({
      access: null,
      refresh: null
    })
    setFavorites(null)
    setUser(null)

    successURL && handleRedirect(successURL)
  }

  const passwordRecovery = async ({ email }, handler, successURL) => {
    const response = await handler({ email })
    const data = await response.json()

    if (response.ok) {
      successURL && handleRedirect(successURL)
    }

    return data
  }

  const passwordReset = async ({ newPassword1, newPassword2, uid, token }, handler, successURL) => {
    const response = await handler({ newPassword1, newPassword2, uid, token })
    const data = await response.json()

    if (response.ok) {
      successURL && handleRedirect(successURL)
    }

    return data
  }

  const getUser = async (query = {}, handler) => {
    const interceptedHandler = interceptor(handler)
    const response = await interceptedHandler(query, {
      Authorization: `Bearer ${accessToken}`
    })

    if (response.ok) {
      const user = await response.json()
      setUser(user)
    }
  }

  const updateUser = async (body, handler) => {
    const interceptedHandler = interceptor(handler)

    const response = await interceptedHandler({ ...body }, {
      Authorization: `Bearer ${accessToken}`
    })

    if (response.ok) {
      const user = await response.json()
      setUser(user)
    }
  }

  const createEnrollment = async ({ course }, handler) => {
    const interceptedHandler = interceptor(handler);
  
    try {
      const response = await interceptedHandler(
        { course },
        { Authorization: `Bearer ${accessToken}` }
      );
  
      if (!response.ok && response.status === 401) {
        const responseRefresh = await tokenRefreshHandler({ refresh: refreshToken })
        const newAccessToken = await responseRefresh.json()
        setupTokens({ ...newAccessToken, refresh: refreshToken })
        const responseAgain = await interceptedHandler(
          { course },
          { Authorization: `Bearer ${newAccessToken.access}` }
        );
        const dataAgain = await responseAgain.json();
        return dataAgain;
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error creating enrollment:", error);
      throw error;
    }
  }
  
  const getEnrolledCourses = async (query = {}, handler) => {
    const interceptedHandler = interceptor(handler)
    const response = await interceptedHandler(
      query,
      { Authorization: `Bearer ${accessToken}` }
    )
    if (response.ok) {
      const data = await response.json()
      setEnrolledCourses(data.items)
    }
  }

  const createSpecializationEnrollment = async ({ specialization }, handler) => {
    const interceptedHandler = interceptor(handler);
  
    try {
      const response = await interceptedHandler(
        { specialization },
        { Authorization: `Bearer ${accessToken}` }
      );
  
      if (!response.ok && response.status === 401) {
        const responseRefresh = await tokenRefreshHandler({ refresh: refreshToken })
        const newAccessToken = await responseRefresh.json()
        setupTokens({ ...newAccessToken, refresh: refreshToken })
        const responseAgain = await interceptedHandler(
          { specialization },
          { Authorization: `Bearer ${newAccessToken.access}` }
        );
        const dataAgain = await responseAgain.json();
        return dataAgain;
      }
  
      const data = await response.json()
      return data;
    } catch (error) {
      console.error("Error creating enrollment:", error);
      throw error;
    }
  }

  const getEnrolledSpecialization = async (query = {}, handler) => {
    const interceptedHandler = interceptor(handler)
    const response = await interceptedHandler(
      query,
      { Authorization: `Bearer ${accessToken}` }
    )
    if (response.ok) {
      const data = await response.json()
      setEnrolledSpecializations(data.items)
    }
  }

  const getFavorites = async (query = {}, handler) => {
    const interceptedHandler = interceptor(handler)
    const response = await interceptedHandler(
      query,
      { Authorization: `Bearer ${accessToken}` }
    )
    if (response.ok) {
      const data = await response.json()
      setFavorites(data)
    }
  }

  const createFavorite = async ({ contentType, objectId }, handler) => {
    const interceptedHandler = interceptor(handler)
    const response = await interceptedHandler(
      { contentType, objectId },
      { Authorization: `Bearer ${accessToken}` }
    )
    const data = await response.json()

    return data
  }

  const deleteFavorite = async (favoriteId, handler) => {
    const interceptedHandler = interceptor(handler)
    await interceptedHandler(
      favoriteId,
      { Authorization: `Bearer ${accessToken}` }
    )
  }

  const createDownload = async ({ resource }, handler) => {
    const interceptedHandler = interceptor(handler)
    const response = await interceptedHandler(
      { resource },
      { Authorization: `Bearer ${accessToken}` }
    )
    const data = await response.json()

    return data
  }

  const createView = async ({ resource }, handler) => {
    const interceptedHandler = interceptor(handler)
    const response = await interceptedHandler(
      { resource },
      { Authorization: `Bearer ${accessToken}` }
    )
    const data = await response.json()

    return data
  }

  const createComment = async ({ page, body }, handler) => {
    const interceptedHandler = interceptor(handler)
    const response = await interceptedHandler(
      { page, body },
      { Authorization: `Bearer ${accessToken}` }
    )

    if (response.ok) {
      setCommentCreated(true)
    }

    const data = await response.json()

    return data
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    loadAuthFunctions()
  }, [])

  useEffect(() => {
    loadAuthFunctions()
  }, [router])

  useEffect(() => {
    if (tokenVerifyHandler !== null) {
      loadTokensFromStorage()
    }
  }, [accessTokenStorageKey, refreshTokenStorageKey])

  useEffect(() => {
    if (tokenVerifyHandler !== null) {
      verifyTokens()
    }
  }, [accessToken, refreshToken])

  // Return the user object and auth methods
  return {
    user,
    favorites,

    signin,
    signup,
    signout,

    passwordRecovery,
    passwordReset,

    passwordRecoveryEmailSent,
    setPasswordRecoveryEmailSent,

    suggestionSent,
    setSuggestionSent,

    isAuthenticated,

    commentCreated,
    setCommentCreated,

    /* Actions */
    getUser,
    updateUser,

    enrolledCourses,
    getEnrolledCourses,

    enrolledSpecializations,
    getEnrolledSpecialization,

    getFavorites,
    createFavorite,
    deleteFavorite,

    createView,
    createDownload,
    createEnrollment,
    createSpecializationEnrollment,
    createComment
  }
}
